import * as React from 'react'
import * as R from 'ramda'
import * as ThemeUI from 'theme-ui'

import * as Herz from '@rushplay/herz'

import * as BaseGameTile from './base-game-tile'
import { Button } from './buttons/button'
import { GameQuery } from './game-query'
import { GameRow } from './game-row'
import { GameTileCasino } from './game-tile/game-tile-casino'
import { GameTileLiveCasino } from './game-tile/live-casino'
import { useSearchQuery } from './use-search-query'

export function SearchPage() {
  const translate = Herz.I18n.useTranslate()
  const searchQuery = useSearchQuery()
  const searchQueryValue = searchQuery['q']

  return (
    <ThemeUI.Box
      sx={{
        maxWidth: 'container.xl',
        mx: 'auto',
        p: 2,
        minHeight: '100%',
      }}
    >
      <GameQuery
        name="search"
        params={{
          text: R.isNil(searchQueryValue) ? '' : searchQueryValue,
          limit: 24,
        }}
      >
        {(gq) => {
          const remainingGames = gq.totalCount - gq.games?.length || 0

          return (
            <ThemeUI.Box>
              {gq?.games?.length === 0 && searchQueryValue && !gq?.isLoading && (
                <ThemeUI.Text
                  as="p"
                  sx={{
                    mt: 2,
                    color: 'primary-text',
                    fontWeight: 500,
                    textAlign: 'center',
                  }}
                >
                  {translate('casino.no-results')}
                </ThemeUI.Text>
              )}

              <GameRow games={gq?.games} size="medium" isUnlimited>
                {(games) =>
                  games?.map((game) => (
                    <BaseGameTile.Loader key={game?.id}>
                      {game.liveCasinoAttributes ? (
                        <GameTileLiveCasino size="medium" game={game} />
                      ) : (
                        <GameTileCasino size="medium" game={game} />
                      )}
                    </BaseGameTile.Loader>
                  ))
                }
              </GameRow>

              {remainingGames !== Infinity && remainingGames > 0 && (
                <Button
                  sx={{
                    mx: 'auto',
                    mt: 4,
                  }}
                  onClick={gq?.onLoadMore}
                  variant="primary"
                >
                  {translate('casino-search.load-more')}
                </Button>
              )}
            </ThemeUI.Box>
          )
        }}
      </GameQuery>
    </ThemeUI.Box>
  )
}

Herz.I18n.Loader.preload(
  ['casino-search.load-more', 'search-games.placeholder', 'casino.no-results'],
  SearchPage
)

// for @loadable/components
export default SearchPage
