import * as ReactRedux from 'react-redux'
import URL from 'url'

import * as ReduxConfig from './redux-configuration'
import * as Configuration from './app-config'
import * as Utils from './utils'
import { toImgProxyUrl } from './utils/to-img-proxy-url'

/**
 * The hook to return a processed Imgproxy URL
 * @param {?string} url Original image URL
 * @param {?Array|?Object} transforms Desired parameters to process the image.
 * @return {Array} Processed Imgproxy URLs.
 * If an object is passed in transforms, will return an array with 1 resulting
 * URL, if an array is passed in transforms, will return an array of URLs.
 */
export function useImgproxyUrl(url, transforms) {
  const dpr = Configuration.useDevicePixelRatio()
  const config = Utils.Configuration.useConfiguration()
  const hasWebpSupport = ReactRedux.useSelector((state) =>
    ReduxConfig.getHasWebpSupport(state.configuration)
  )

  if (!url) {
    return []
  }

  return Array.isArray(transforms)
    ? transforms.map((transform) =>
        toImgProxyUrl(config.imgproxyUrl, url, {
          ...transform,
          dpr,
          extension: hasWebpSupport ? 'webp' : transform?.extension,
        })
      )
    : [
        toImgProxyUrl(config.imgproxyUrl, url, {
          ...transforms,
          dpr,
          extension: hasWebpSupport ? 'webp' : transforms?.extension,
        }),
      ]
}

/**
 * The hook to return a processed Imgproxy URL for a translation image
 * @param {?string} url Original image URL (translation image)
 * @param {?Array|?Object} transforms Desired parameters to process the image.
 * @return {Array} Processed Imgproxy URLs.
 * If an object is passed in transforms, will return an array with 1 resulting
 * URL, if an array is passed in transforms, will return an array of URLs.
 */
export function useTranslationImgproxyUrl(url, transforms) {
  const { pathname } = URL.parse(url || '')

  return useImgproxyUrl(pathname, transforms)
}
