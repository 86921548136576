import * as ThemeUI from 'theme-ui'
import * as PropTypes from 'prop-types'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'

import * as Icons from '../icons'

export function GameSectionMenu(props) {
  const Icon = props.icon

  return (
    <ThemeUI.Flex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'primary-text',
      }}
    >
      <ThemeUI.Flex
        sx={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <ThemeUI.Flex
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <ThemeUI.Flex
            sx={{
              alignItems: 'center',
            }}
          >
            {props.icon && (
              <ThemeUI.Flex
                sx={{
                  color: 'primary',
                  pr: 1,
                  fontSize: '24px',
                  alignItems: 'center',
                }}
              >
                <Icon />
              </ThemeUI.Flex>
            )}
            <ThemeUI.Text
              as="h2"
              sx={{
                fontSize: 'xl',
                // make sure that live casino link fits
                maxWidth: 'calc(100vw - 150px)',
                lineHeight: 'lg',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                fontFamily: 'heading',
              }}
            >
              {props.title}
            </ThemeUI.Text>
          </ThemeUI.Flex>

          {props.to && props.linkText && (
            <ThemeUI.Link
              as={ReactRouter.Link}
              to={props.to}
              sx={{
                color: 'primary',
                textDecoration: 'none',
                fontSize: '3xl',
              }}
            >
              <ThemeUI.Flex
                sx={{
                  alignItems: 'center',
                }}
              >
                <ThemeUI.Box
                  sx={{
                    fontSize: 'md',
                    fontWeight: 600,
                  }}
                >
                  {props.linkText}
                </ThemeUI.Box>
                <Icons.ChevronRight />
              </ThemeUI.Flex>
            </ThemeUI.Link>
          )}
        </ThemeUI.Flex>
        <ThemeUI.Text
          sx={{
            fontSize: 'sm',
            fontWeight: '400',
            lineHeight: '16px',
            color: 'white-alpha.600',
            mt: [1, null, 3],
            mb: 1,
          }}
        >
          {props.seo}
        </ThemeUI.Text>
      </ThemeUI.Flex>

      {props.children}
    </ThemeUI.Flex>
  )
}

GameSectionMenu.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  linkText: PropTypes.string,
  seo: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.func,
}
