import * as React from 'react'
import PropTypes from 'prop-types'

import * as Casino from '@rushplay/casino'

import * as Constants from './constants'
import { Nothing } from './utils/components'
import { useClientType } from './app-config'

export function GameQuery(props) {
  const clientType = useClientType()
  const isMobile = clientType === Constants.ClientType.MOBILE_BROWSER

  if (clientType === Constants.ClientType.UNKNOWN) {
    return Nothing
  }

  return (
    <Casino.GameQuery
      {...props}
      mountPoint="casino"
      params={{
        ...props.params,
        mobile: isMobile,
      }}
    >
      {props.children}
    </Casino.GameQuery>
  )
}

GameQuery.propTypes = {
  children: PropTypes.PropTypes.func,
  games: PropTypes.PropTypes.array,
  mountPoint: PropTypes.PropTypes.string,
  name: PropTypes.PropTypes.string.isRequired,
  isLoading: PropTypes.PropTypes.bool,
  ids: PropTypes.PropTypes.array,
  params: PropTypes.PropTypes.shape({
    mobile: PropTypes.PropTypes.bool,
    limit: PropTypes.PropTypes.number,
    offset: PropTypes.PropTypes.number,
    order: PropTypes.PropTypes.string,
    tags: PropTypes.PropTypes.arrayOf(PropTypes.string),
    recent: PropTypes.PropTypes.bool,
    jackpot: PropTypes.PropTypes.bool,
    text: PropTypes.PropTypes.string,
  }),
  totalCount: PropTypes.PropTypes.number,
  onClear: PropTypes.PropTypes.func,
  onFetch: PropTypes.PropTypes.func,
  onLoadMore: PropTypes.PropTypes.func,
}
