import * as React from 'react'
import * as R from 'ramda'
import * as PropTypes from 'prop-types'
import * as ThemeUI from 'theme-ui'

import * as Herz from '@rushplay/herz'

import { GameRow } from './game-row'
import { GameSectionMenu } from './game-section/game-section-menu'
import { gameCardVariants } from './theming/cards'

const translationKeys = {
  viewAllBtn: 'casino.view-all-button',
  viewLessBtn: 'casino.view-less-button',
}

export function ExpandableGameRow(props) {
  const translate = Herz.I18n.useTranslate()
  const [viewAll, setViewAll] = React.useState(false)
  const [isExpandable, setIsExpandable] = React.useState(false)
  const [visibleGames, setVisibleGames] = React.useState()

  React.useEffect(() => {
    setIsExpandable(props.games.length > visibleGames)
  }, [visibleGames, props.games.length])

  return (
    <React.Fragment>
      <GameSectionMenu
        title={props.title}
        seo={props.seo}
        iconUrl={props.iconUrl}
      >
        {(isExpandable || viewAll) && (
          <ThemeUI.Link
            onClick={() => setViewAll(R.not)}
            sx={{
              color: 'faded-white',
              textDecoration: 'none',
              flexShrink: 0,
              alignSelf: 'flex-start',
            }}
          >
            <ThemeUI.Flex>
              <ThemeUI.Box
                sx={{
                  fontSize: 'sm',
                  fontWeight: 700,
                }}
              >
                {viewAll
                  ? translate(translationKeys.viewLessBtn)
                  : translate(translationKeys.viewAllBtn)}
              </ThemeUI.Box>
            </ThemeUI.Flex>
          </ThemeUI.Link>
        )}
      </GameSectionMenu>

      <GameRow
        games={props.games}
        isUnlimited={viewAll}
        size={props.size}
        onVisibleElementsChange={setVisibleGames}
      >
        {(games) => props.children(games)}
      </GameRow>
    </React.Fragment>
  )
}

Herz.I18n.Loader.preload(
  [translationKeys.viewAllBtn, translationKeys.viewLessBtn],
  ExpandableGameRow
)

ExpandableGameRow.propTypes = {
  games: PropTypes.array,
  title: PropTypes.string,
  seo: PropTypes.string,
  size: PropTypes.oneOf(gameCardVariants),
  children: PropTypes.func,
  iconUrl: PropTypes.string,
}
