import * as React from 'react'
import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as ThemeUI from 'theme-ui'

import * as Herz from '@rushplay/herz'

import * as BaseGameTile from './base-game-tile'
import * as Suspense from './suspense'
import { Button } from './buttons/button'
import { GameRow } from './game-row'
import { GameTileLiveCasino } from './game-tile/live-casino'
import { PageLayout } from './page-layout'
import { SeoTextSection } from './seo-text-section'
import { SubMenu } from './sub-menu'
import { VerticalBanner } from './vertical-banner'
import { useLiveCasinoTablesQuery } from './use-live-casino-query'

const liveCasinoCategories = [
  'all',
  'roulette',
  'blackjack',
  'baccarat',
  'game-shows',
  'other',
]

function LiveCasinoSubNav() {
  const translate = Herz.I18n.useTranslate()
  const params = ReactRouter.useParams()
  return (
    <SubMenu>
      {liveCasinoCategories.map((category) => (
        <SubMenu.Link
          key={category}
          to={`/live-casino/${category}`}
          active={!params.category && category === 'all'}
        >
          {translate(`casino.${category}`)}
        </SubMenu.Link>
      ))}
    </SubMenu>
  )
}

Herz.I18n.Loader.preload(
  [
    'casino.all',
    'casino.roulette',
    'casino.blackjack',
    'casino.baccarat',
    'casino.game-shows',
    'casino.other',
  ],
  LiveCasinoSubNav
)

export function LiveCasinoPage() {
  return (
    <PageLayout headerPost={<LiveCasinoSubNav />}>
      <VerticalBanner />

      <ThemeUI.Container
        sx={{
          maxWidth: 'container.xl',
          marginX: 'auto',
          p: 2,
        }}
      >
        <Suspense.Boundary>
          <LiveCasinoGames />
        </Suspense.Boundary>

        <SeoTextSection />
      </ThemeUI.Container>
    </PageLayout>
  )
}

const DEFAULT_LIMIT = 12

function LiveCasinoGames() {
  const translate = Herz.I18n.useTranslate()
  const params = ReactRouter.useParams()
  const [, filteredGames] = useLiveCasinoTablesQuery(params.category || 'all')

  const [limit, setLimit] = React.useState(DEFAULT_LIMIT)
  const hasMoreGames = filteredGames?.length > limit

  const games = hasMoreGames ? filteredGames?.slice(0, limit) : filteredGames

  React.useEffect(() => {
    setLimit(DEFAULT_LIMIT)
  }, [params.category])

  return (
    <>
      <GameRow isUnlimited size="medium" games={games}>
        {(games) =>
          R.map(
            (game) => (
              <BaseGameTile.Loader key={game.id}>
                <GameTileLiveCasino size="medium" game={game} />
              </BaseGameTile.Loader>
            ),
            games
          )
        }
      </GameRow>
      {hasMoreGames && (
        <ThemeUI.Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
          }}
        >
          <Button variant="primary" onClick={() => setLimit(Infinity)}>
            {translate('casino.load-more-button')}
          </Button>
        </ThemeUI.Box>
      )}
    </>
  )
}

Herz.I18n.Loader.preload(['casino.load-more-button'], LiveCasinoGames)

// for @loadable/components
export default LiveCasinoPage
