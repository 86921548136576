import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as PropTypes from 'prop-types'
import * as Urql from 'urql'
import gql from 'graphql-tag'

import * as Herz from '@rushplay/herz'

import * as BaseGameTile from './base-game-tile'
import * as Suspense from './suspense'
import * as Utils from './utils'
import * as GameTile from './game-tile'
import { Button } from './buttons/button'
import { ExpandableGameRow } from './expandable-game-row'
import { GameRow } from './game-row'
import { GameSearch } from './game-search'
import { GameSectionMenu } from './game-section/game-section-menu'
import { GameTileCasino } from './game-tile/game-tile-casino'
import { GameTileLiveCasino } from './game-tile/live-casino'
import { NotFound } from './not-found'
import { PageLayout } from './page-layout'
import { SearchPage } from './search-page'
import { SeoTextSection } from './seo-text-section'
import { SubMenu } from './sub-menu'
import { VerticalBanner } from './vertical-banner'
import { gameTileFragment } from './game-tile/utils'

const SEARCH_URL = '/casino/search'

const translationKeys = {
  loadMoreBtn: 'casino.load-more-button',
}

const SubMenuCategoriesQuery = gql`
  query SubMenuCategoriesQuery {
    catalog(key: "main") {
      ... on GetCatalogSuccess {
        categories {
          key
          id
        }
      }
      ... on GetCatalogFailure {
        error
        errorCode
      }
    }
  }
`

function CasinoSubNav(props) {
  const location = ReactRouter.useLocation()
  const [response] = Urql.useQuery({ query: SubMenuCategoriesQuery })
  const translate = Herz.I18n.useTranslate(
    () =>
      response.data?.catalog?.categories.map(
        (category) => `casino.${category.key}`
      ),
    [response.data]
  )
  const disableOverflow = location.pathname === SEARCH_URL

  if (response.fetching || response.data?.catalog?.error) {
    return null
  }

  return (
    <SubMenu disableOverflow={disableOverflow} searchbar={<GameSearch />}>
      {response.data?.catalog?.categories.map((category) => (
        <SubMenu.Link
          key={category.key}
          to={`/casino/${category.key}`}
          active={props.activeUrl === `/${category.key}`}
        >
          {translate(`casino.${category.key}`)}
        </SubMenu.Link>
      ))}
    </SubMenu>
  )
}

CasinoSubNav.propTypes = {
  activeUrl: PropTypes.string,
  disableOverflow: PropTypes.bool,
}

export function CasinoPage() {
  const location = ReactRouter.useLocation()
  const [response] = Urql.useQuery({ query: SubMenuCategoriesQuery })

  const defaultCasinoRoute = response.data?.catalog?.categories[0] ?? {}

  if (response.fetching || response.data?.catalog?.error) {
    return null
  }

  const locationCategory = location.pathname.replace('/casino', '')

  return (
    <PageLayout
      headerPost={
        <CasinoSubNav
          activeUrl={
            locationCategory === ''
              ? `/${defaultCasinoRoute.key}`
              : `/${locationCategory}`
          }
        />
      }
    >
      <Suspense.Boundary>
        <VerticalBanner />
      </Suspense.Boundary>
      <ReactRouter.Switch>
        <ReactRouter.Route path="/casino/search" component={SearchPage} />

        <ReactRouter.Route exact path="/casino/">
          <Suspense.Boundary>
            <CasinoCategory category={defaultCasinoRoute.id} />
          </Suspense.Boundary>
        </ReactRouter.Route>

        <ReactRouter.Route path="/casino/:category">
          <Suspense.Boundary>
            <CasinoCategory categories={response.data?.catalog?.categories} />
          </Suspense.Boundary>
        </ReactRouter.Route>
      </ReactRouter.Switch>
    </PageLayout>
  )
}

const GetCategoryQuery = gql`
  query GetCategoryQuery($id: ID!) {
    catalogCategory(id: $id) {
      id
      key
      sections {
        tileSize
        id
        key
        layout
        totalGamesCount
        games {
          ...GameTileFragment
        }
      }
    }
  }
  ${gameTileFragment}
`

function CasinoCategory(props) {
  const session = Herz.Auth.useSession()
  const params = ReactRouter.useParams()
  const [response] = Urql.useQuery({
    query: GetCategoryQuery,
    variables: {
      id:
        props.category ||
        props.categories?.find((el) => el.key === params.category)?.id,
    },
    requestPolicy: 'cache-and-network',
  })

  if (response.data === null) {
    return <NotFound />
  }

  return (
    <ThemeUI.Box
      sx={{
        'maxWidth': 'container.xl',
        'mx': 'auto',
        'p': 2,
        '& > *:not(:last-child)': {
          mb: 4,
        },
      }}
    >
      {response.data?.catalogCategory.sections.map(
        (section) =>
          (session.authenticated || section.key !== 'recent') && (
            <ThemeUI.Box key={section.key}>
              <Suspense.Boundary>
                <CasinoCategorySection
                  id={section.id}
                  tileSize={section.tileSize}
                  sectionKey={section.key}
                  games={section.games}
                  layout={section.layout}
                  totalGamesCount={section.totalGamesCount}
                />
              </Suspense.Boundary>
            </ThemeUI.Box>
          )
      )}

      <SeoTextSection />
    </ThemeUI.Box>
  )
}

CasinoCategory.propTypes = {
  category: PropTypes.string,
  categories: PropTypes.array,
}

function CasinoCategorySection(props) {
  const translate = Herz.I18n.useTranslate(
    () => [
      `casino.${props.sectionKey}`,
      `${props.sectionKey}.seo`,
      `casino.${props.sectionKey}.icon`,
    ],
    [props.sectionKey]
  )

  return (
    <React.Fragment>
      <Utils.Components.Branch
        test={props.layout === 'row'}
        left={
          <ExpandableGameRow
            title={translate(`casino.${props.sectionKey}`)}
            seo={translate(`${props.sectionKey}.seo`)}
            size={props.tileSize}
            games={props.games}
            iconUrl={translate(`casino.${props.sectionKey}.icon`)}
          >
            {R.map((game) => (
              <BaseGameTile.Loader key={game.id}>
                {game.liveCasinoAttributes ? (
                  <GameTileLiveCasino size={props.tileSize} game={game} />
                ) : (
                  <GameTileCasino size={props.tileSize} game={game} />
                )}
              </BaseGameTile.Loader>
            ))}
          </ExpandableGameRow>
        }
        right={
          <React.Fragment>
            <GameSectionMenu
              title={translate(`casino.${props.sectionKey}`)}
              iconUrl={translate(`casino.${props.sectionKey}.icon`)}
            />

            <GameRow isUnlimited size={props.tileSize}>
              <GameGridPaginated
                sectionId={props.id}
                offset={0}
                limit={24}
                key={props.id}
              />
            </GameRow>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}

CasinoCategorySection.propTypes = {
  id: PropTypes.string,
  tileSize: PropTypes.string,
  sectionKey: PropTypes.string,
  games: PropTypes.array,
  layout: PropTypes.oneOf(['row', 'grid']),
  totalGamesCount: PropTypes.number,
}

const getCasinoSection = gql`
  query CasinoLoadMoreSection($id: ID!, $limit: Int!, $offset: Int!) {
    catalogSection(id: $id, limit: $limit, offset: $offset) {
      id
      totalGamesCount
      games {
        displayProvider
        jackpotCurrency
        jackpotValue
        key
        ...GameTileFragment
      }
    }
  }
  ${gameTileFragment}
`

function GameGridPaginated(props) {
  const [isPaused, setIsPaused] = React.useState(true)
  const translate = Herz.I18n.useTranslate()

  const [response] = Urql.useQuery({
    query: getCasinoSection,
    variables: {
      limit: props.limit,
      offset: props.offset,
      id: props.sectionId,
    },
  })

  if (props.offset >= response.data?.catalogSection.totalGamesCount) {
    return null
  }

  return (
    <React.Fragment>
      {response.data?.catalogSection.games.map((game, tileIndex) => (
        <BaseGameTile.Loader key={game.id}>
          <GameTile.Animate tileIndex={tileIndex}>
            {game.liveCasinoAttributes ? (
              <GameTileLiveCasino size="medium" game={game} />
            ) : (
              <GameTileCasino size="medium" game={game} />
            )}
          </GameTile.Animate>
        </BaseGameTile.Loader>
      ))}

      <Utils.Components.Branch
        test={isPaused}
        left={
          <Utils.Components.When
            condition={
              response.data?.catalogSection.totalGamesCount >=
              props.limit + props.offset
            }
          >
            <Button
              sx={{
                width: ['50%', '40%', '30%'],
                gridColumnStart: 1,
                gridColumnEnd: -1,
                mx: 'auto',
              }}
              onClick={() => setIsPaused(false)}
              variant="primary"
            >
              {translate(translationKeys.loadMoreBtn)}
            </Button>
          </Utils.Components.When>
        }
        right={
          <Suspense.Boundary>
            <GameGridPaginated
              sectionId={props.sectionId}
              limit={props.limit}
              offset={props.offset + props.limit}
              totalGamesCount={response.data?.catalogSection.totalGamesCount}
            >
              {props.children}
            </GameGridPaginated>
          </Suspense.Boundary>
        }
      />
    </React.Fragment>
  )
}

Herz.I18n.Loader.preload([translationKeys.loadMoreBtn], GameGridPaginated)

GameGridPaginated.propTypes = {
  sectionId: PropTypes.string,
  limit: PropTypes.number,
  offset: PropTypes.number,
  totalGamesCount: PropTypes.number,
  children: PropTypes.node,
}

// for @loadable/components
export default CasinoPage
