import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import * as Urql from 'urql'
import gql from 'graphql-tag'

const PaymentProvidersQuery = gql`
  query FooterSectionsQuery {
    configuration {
      footerSections {
        paymentProviders {
          image
          name
        }
      }
    }
  }
`

export function PaymentMethods() {
  const [response] = Urql.useQuery({ query: PaymentProvidersQuery })
  const paymentProviders =
    response.data?.configuration?.footerSections?.paymentProviders

  if (response?.fetching || paymentProviders?.length === 0) {
    return null
  }

  return (
    <ThemeUI.Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 3,
        justifyContent: 'center',
      }}
    >
      {paymentProviders.map((item, index) => (
        <img
          key={`payment_provider_${index}_${item.name}`}
          src={item.image}
          loading="lazy"
          alt={`${item.name}-logo`}
          height="20px"
          width="auto"
        />
      ))}
    </ThemeUI.Box>
  )
}
