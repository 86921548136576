import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

function isHttpCodeValid(code) {
  // prettier-ignore
  const validCodes = [
    // 1xx informational response
    100, 101, 102, 103,
    // 2xx success
    200, 201, 202, 203, 204, 205, 206, 207, 208, 226,
    // 3xx redirection
    300, 301, 302, 303, 304, 305, 306, 307, 308,
    // 4xx client errors
    400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414,
    415, 416, 417, 418, 421, 422, 423, 424, 425, 426, 428, 429, 431, 451,
    // 5xx server errors
    500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511
  ]

  return validCodes.includes(code)
}

export function HttpStatus(props) {
  if (process.env.NODE_ENV !== 'production') {
    if (!isHttpCodeValid(props.code)) {
      throw new Error('Invalid HTTP code')
    }
  }

  return (
    <ReactRouter.Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.status = props.code
        }

        return props.children
      }}
    />
  )
}

HttpStatus.propTypes = {
  children: PropTypes.element,
  code: PropTypes.number,
}
