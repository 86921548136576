import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ThemeUI from 'theme-ui'
import * as PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'

import * as Icons from './icons'
import { useSearchQuery } from './use-search-query'

const SEARCH_URL = '/casino/search'

export function GameSearchInput() {
  const inputRef = React.useRef(null)
  const [focused, setFocused] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const [backLink, setBackLink] = React.useState('/casino')
  const translate = Herz.I18n.useTranslate()

  const searchQuery = useSearchQuery()
  const history = ReactRouter.useHistory()
  const location = ReactRouter.useLocation()

  const [searchString, setSearchString] = React.useState(searchQuery['q'] ?? '')

  function handleToggle() {
    if (visible) {
      setSearchString('')
      setVisible(false)
      history.replace(backLink)
    } else {
      setVisible(true)
      history.replace(SEARCH_URL)
    }
  }

  React.useEffect(() => {
    if (visible) {
      if (searchString?.length > 1) {
        history.replace(`${SEARCH_URL}?q=${searchString}`)
      } else {
        history.replace(SEARCH_URL)
      }
    }
  }, [history, searchString, visible])

  React.useEffect(() => {
    if (!visible) {
      setBackLink(location.pathname)
    }
  }, [visible, location.pathname])

  React.useEffect(() => {
    if (focused && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focused, inputRef])

  return (
    <ThemeUI.Box
      as="label"
      sx={{
        alignItems: 'center',
        display: 'flex',
        color: 'faded-white',
        backgroundColor: 'secondary-bg',
        fontSize: 'md',
        width: visible ? 'calc(100vw - 16px)' : '38px',
        flexShrink: 0,
        transition: 'width 200ms ease-in-out',
      }}
      ref={inputRef}
    >
      <ThemeUI.Box
        sx={{
          display: 'grid',
          placeItems: 'center',
          py: ['8px', null, '8px'],
          my: ['6px', null, '9px'],
          pl: '8px',
          pr: '12px',
          fontSize: 'md',
          cursor: 'pointer',
          borderRightStyle: 'solid',
          borderWidth: visible ? '0px' : '1px',
          borderColor: 'faded-white',
        }}
        onClick={handleToggle}
      >
        <Icons.Search />
      </ThemeUI.Box>

      {visible && (
        <>
          <ThemeUI.Input
            autoFocus
            sx={{
              width: '100%',
              fontSize: 'md',
              color: 'faded-white',
              lineHeight: '1em',
              pt: '8px',
              pb: '2px',
              px: '4px',
              mx: '8px',
              mb: '6px',
              borderBottom: '1px solid',
              borderColor: 'faded-white',
              borderRadius: '0px',
            }}
            placeholder={translate('search-games.placeholder')}
            type="text"
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value)
            }}
            onBlur={() => setFocused(false)}
            onFocus={() => setFocused(true)}
          />

          <ThemeUI.Box
            sx={{
              display: 'grid',
              placeItems: 'center',
              py: ['14px', null, '18px'],
              px: '8px',
              fontSize: 'sm',
              cursor: 'pointer',
              color: 'faded-white',
            }}
            onClick={handleToggle}
          >
            <Icons.Close />
          </ThemeUI.Box>
        </>
      )}
    </ThemeUI.Box>
  )
}

GameSearchInput.propTypes = {
  autoFocus: PropTypes.bool,
  sx: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visited: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
}

Herz.I18n.Loader.preload(['search-games.placeholder'], GameSearchInput)
