import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import * as Urql from 'urql'
import gql from 'graphql-tag'

const FooterSectionsQuery = gql`
  query FooterSectionsQuery {
    configuration {
      footerSections {
        gameProviders {
          image
          name
        }
      }
    }
  }
`
export function Partners() {
  const [response] = Urql.useQuery({ query: FooterSectionsQuery })

  const gameProviders =
    response.data?.configuration?.footerSections?.gameProviders

  if (response?.fetching || gameProviders?.length === 0) {
    return null
  }

  return (
    <ThemeUI.Flex
      sx={{
        flexWrap: 'wrap',
        columnGap: ['24px', null, '56px'],
        rowGap: 1,
        justifyContent: 'center',
        alignItems: 'center',
        opacity: '0.8',
      }}
    >
      {gameProviders.map((item) => (
        <img
          key={`${item.name}`}
          src={item.image}
          loading="lazy"
          alt={`${item.name}-logo`}
          width="100%"
          style={{
            maxWidth: '150px',
          }}
        />
      ))}
    </ThemeUI.Flex>
  )
}
