import * as R from 'ramda'
import assert from 'assert'

const defaultParams = {
  cropWidth: 0,
  cropHeight: 0,
  dpr: 1,
  enlarge: false,
  gravity: 'ce',
  offsetX: 0,
  offsetY: 0,
  resizingType: 'fill',
  resizingHeight: 0,
  resizingWidth: 0,
}
export const extensionFormats = ['jpg', 'png', 'webp']
export const resizingTypes = ['fill', 'fit', 'auto']
export const gravityTypes = [
  'no',
  'so',
  'ea',
  'we',
  'noea',
  'nowe',
  'soea',
  'sowe',
  'ce',
  'sm',
  'fp',
]

/** Format a URL for use with an imgproxy server
 *
 * @param imgproxyUrl - the imgproxyUrl, can be obtained by calling `useImgProxyUrl`
 * @param source - the source of your image
 * @param options - read [here](https://docs.imgproxy.net/#/generating_the_url_advanced) to get info. about the options
 * */
export function toImgProxyUrl(
  imgproxyUrl = '',
  source = '',
  {
    cropHeight = defaultParams.cropHeight,
    cropWidth = defaultParams.cropWidth,
    dpr = defaultParams.dpr,
    enlarge = defaultParams.enlarge,
    extension = '',
    gravity = defaultParams.gravity,
    resizingHeight = defaultParams.resizingHeight,
    resizingType = defaultParams.resizingType,
    resizingWidth = defaultParams.resizingWidth,
    focusPointX,
    offsetX = defaultParams.offsetX,
    focusPointY,
    offsetY = defaultParams.offsetY,
  } = {}
) {
  if (source == null) {
    return null
  }

  const signature = 'insecure'

  if (process.env.NODE_ENV !== 'production') {
    assert(resizingTypes.includes(resizingType), `Incorrect "resizingType"`)

    assert(gravityTypes.includes(gravity), `Incorrect "gravity"`)

    if (gravity === 'fp') {
      assert(
        focusPointX && focusPointY,
        `"focusPointX" and "focusPointY" are required when "gravity" is "fp"`
      )
    }

    assert(
      [...extensionFormats, ''].includes(extension),
      `Incorrect "extension"`
    )
  }

  // https://docs.imgproxy.net/#/generating_the_url_advanced
  // Refer to the above to find out why these are defined as so
  const processingOptions = {
    resize: [resizingType, resizingWidth, resizingHeight, enlarge ? 1 : 0],
    dpr: [dpr],
    crop: [cropWidth, cropHeight],
    gravity:
      gravity === 'fp'
        ? ['fp', focusPointX, focusPointY]
        : [gravity, offsetX, offsetY],
  }

  const processingKeys = R.keys(processingOptions)

  // Reduce the options into a URL string for imgproxy server to be able to parse it
  const formattedProcessingOptions = R.reduce(
    (options, processKey) => {
      const option = processingOptions[processKey]
      const hasNilOption = R.any(R.isNil, option)

      if (R.not(hasNilOption)) {
        return (options +=
          R.join(':', R.prepend(processKey, option)) +
          (R.not(isLastItemInArray(processKey, processingKeys)) ? '/' : ''))
      }

      return options
    },
    '',
    processingKeys
  )

  function hasCustomExtension(source, extension) {
    return R.length(extension) && R.not(R.includes(extension, source))
  }

  return `${imgproxyUrl}/${signature}/${formattedProcessingOptions}/plain${source}${
    hasCustomExtension(source, extension) ? `@${extension}` : ''
  }`
}

function isLastItemInArray(item, array) {
  return R.indexOf(item, array) === R.length(array) - 1
}
