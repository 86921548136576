import * as React from 'react'
import * as Urql from 'urql'
import * as R from 'ramda'
import gql from 'graphql-tag'

const isTableOpen = (game) => game.tableOpen === true

const hasSeatsAvailable = (game) => game.seatsAvailable !== 0

const isOfGameType = (category) => (game) =>
  category === 'all' ||
  R.includes(category, game.tags) ||
  game.gameType === category

const LiveCasinoTablesQuery = gql`
  query LiveCasinoTablesQuery {
    liveCasinoTables {
      backgroundUrl
      backgroundOverlayUrl
      backgroundOverlayImageAlignment
      backgroundColor
      betBehind
      colorMode
      dealerName
      gameType
      id
      imageUrl
      maxBetCents
      minBetCents
      seatsAvailable
      seatsTotal
      tableOpen
      tags
      title
      titleUrl
      provider
    }
  }
`

// TODO: Refactor impl.
export function useLiveCasinoTablesQuery(category) {
  const [response] = Urql.useQuery({ query: LiveCasinoTablesQuery })
  const [allGames, setAllGames] = React.useState()
  const [filteredGames, setFilteredGames] = React.useState([])

  React.useEffect(() => {
    if (response.data) {
      setAllGames(response.data.liveCasinoTables)
    }
  }, [response.data])

  React.useEffect(() => {
    setFilteredGames(
      R.filter(
        R.allPass([isTableOpen, hasSeatsAvailable, isOfGameType(category)]),
        R.values(allGames)
      )
    )
  }, [category, allGames])

  return [
    {
      data: allGames || response.data,
      error: response.error,
      fetching: response.fetching,
    },
    filteredGames,
  ]
}
