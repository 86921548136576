import * as ThemeUI from 'theme-ui'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Icons from './icons'

export function Spinner(props) {
  return (
    <ThemeUI.Flex
      sx={{
        justifyContent: 'center',
        pt: '64px',
        height: '100%',
        fontSize: ['36px', '48px'],
        ...props.sx,
      }}
    >
      <Icons.Spinner />
    </ThemeUI.Flex>
  )
}

Spinner.propTypes = {
  sx: PropTypes.object,
}
