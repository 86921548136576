import * as React from 'react'
import PropTypes from 'prop-types'

export function PageLayout(props) {
  return (
    <>
      {props.headerPost}
      {props.children}
    </>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node,
  headerPost: PropTypes.node,
}

// for @loadable/components
export default PageLayout
