import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import * as R from 'ramda'
import * as PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'

import * as BaseGameTile from '../base-game-tile'
import * as Constants from '../constants'
import { Amount } from '../amount'

export function GameTileLiveCasino(props) {
  const session = Herz.Auth.useSession()

  const playPath = `/play-live/${props.game.id}`

  return (
    <BaseGameTile.GameLink
      to={session.authenticated ? playPath : `?login=me&referrer=${playPath}`}
    >
      <BaseGameTile.Background
        backgroundUrl={props.game?.backgroundUrl}
        backgroundOverlayImageAlignment={
          props.game?.backgroundOverlayImageAlignment
        }
        backgroundOverlayUrl={props.game?.backgroundOverlayUrl}
        disabled={props.game?.disabled}
        size={props.size}
      >
        <ThemeUI.Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            color: 'white-alpha.900',
          }}
        >
          <BaseGameTile.GameProvider>
            {props.game?.displayProvider || props.game?.provider}
          </BaseGameTile.GameProvider>
          <ThemeUI.Box
            sx={{
              height: '50%',
              position: 'absolute',
              left: '0px',
              bottom: '0px',
              right: '0px',
              background:
                'linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000)',
              borderBottomLeftRadius: 1,
              borderBottomRightRadius: 1,
            }}
          />
          <BaseGameTile.Title size={props.size}>
            {props.game?.title}
          </BaseGameTile.Title>
          <Statistics
            backgroundOverlayImageAlignment={
              props.game?.backgroundOverlayImageAlignment
            }
            liveStatistics={
              props.game?.minBetCents ||
              props.game?.liveCasinoAttributes?.seatsAvailable
            }
          />
        </ThemeUI.Box>
      </BaseGameTile.Background>
    </BaseGameTile.GameLink>
  )
}

GameTileLiveCasino.defaultProps = {
  game: {},
}

GameTileLiveCasino.propTypes = {
  size: PropTypes.oneOf(Constants.GameTileSizes),
  game: PropTypes.object,
}

function Statistics(props) {
  if (!props.liveStatistics) {
    return null
  }

  return (
    <ThemeUI.Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        fontSize: ['10px', 'xs'],
        mt: 1,
        mb: R.equals(props.backgroundOverlayImageAlignment, 'bottom')
          ? 1
          : null,
        zIndex: '1',
      }}
    >
      {R.keys(props.liveStatistics).map((key, index) => {
        const isLastItem = R.equals(
          index + 1,
          R.length(R.keys(props.liveStatistics))
        )

        return (
          <React.Fragment key={key}>
            {key === 'minBetCents' && (
              <ThemeUI.Text variant="game-tile">
                <Amount>{props.liveStatistics[key]}</Amount>
              </ThemeUI.Text>
            )}
            {key === 'seatsAvailable' && props.liveStatistics[key] && (
              <ThemeUI.Text variant="game-tile">
                {props.liveStatistics[key]} seats
              </ThemeUI.Text>
            )}

            {!isLastItem && (
              <ThemeUI.Box as="span" sx={{ marginX: '2px' }}>
                |
              </ThemeUI.Box>
            )}
          </React.Fragment>
        )
      })}
    </ThemeUI.Box>
  )
}

Statistics.propTypes = {
  backgroundOverlayImageAlignment: PropTypes.string,
  liveStatistics: PropTypes.object,
}
