import * as React from 'react'

import * as Forms from '@rushplay/forms'

import { GameSearchInput } from './game-search-input'

const dataSchema = {
  type: 'object',
  properties: {
    q: {
      type: 'string',
      minLength: 1,
    },
  },
  required: ['q'],
}

export function GameSearch() {
  return (
    <Forms.Provider schema={dataSchema} name="search-games">
      <GameSearchInput />
    </Forms.Provider>
  )
}
