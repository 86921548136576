import * as React from 'react'
import * as PropTypes from 'prop-types'
import * as R from 'ramda'
import * as ThemeUI from 'theme-ui'

import * as Configuration from './app-config'
import * as Utils from './utils'
import * as Foundations from './theming/foundations'
import { toImgProxyUrl } from './utils/to-img-proxy-url'

/**
 * Image component for resizing and converting remote images.
 * The component is using imgproxy API, see [imgproxy documentation]{@link https://github.com/imgproxy/imgproxy/blob/master/docs/generating_the_url.md} for more information
 * @param {Object} props
 */
export function DynamicImage(props) {
  const dpr = Configuration.useDevicePixelRatio()
  const config = Utils.Configuration.useConfiguration()

  // Image processing options
  const options = {
    cropHeight: props.cropHeight,
    cropWidth: props.cropWidth,
    dpr,
    enlarge: props.enlarge,
    extension: props.extension,
    gravity: props.gravity,
    resizingHeight: props.resizingHeight,
    resizingType: props.resizingType,
    // Take first width if its a breakpoint array, to use as default in image
    resizingWidth: Array.isArray(props.resizingWidth)
      ? props.resizingWidth[0]
      : props.resizingWidth,
    focusPointX: props.focusPointX,
    focusPointY: props.focusPointY,
    offsetX: props.offsetX,
    offsetY: props.offsetY,
  }

  const webpOptions = R.assoc('extension', 'webp', options)

  return (
    // HTML Picture element, mapping over the different sizes to have plenty of sources
    // available for the browser to choose from
    <picture style={{ display: 'contents' }}>
      {Array.isArray(props.resizingWidth) &&
        // Create webp images from all possible resizing sizes, if there are multiple
        R.reverse(R.drop(1, props.resizingWidth)).map((width, index) => (
          <source
            key={`webp-${index}`}
            media={`(min-width: ${R.path(
              [index],
              R.reverse(
                R.take(props.resizingWidth.length - 1, Foundations.breakpoints)
              )
            )})`}
            type="image/webp"
            srcSet={toImgProxyUrl(
              config.imgproxyUrl,
              props.source,
              R.assoc('resizingWidth', width, webpOptions)
            )}
          />
        ))}
      {/* Webp image source with the webpOptions */}
      <source
        type="image/webp"
        srcSet={toImgProxyUrl(config.imgproxyUrl, props.source, webpOptions)}
      />
      {Array.isArray(props.resizingWidth) &&
        // Create normal images from all possible resizing sizes, if there are multiple
        R.reverse(R.drop(1, props.resizingWidth)).map((width, index) => (
          <source
            key={index}
            media={`(min-width: ${R.path(
              [index],
              R.reverse(
                R.take(props.resizingWidth.length - 1, Foundations.breakpoints)
              )
            )})`}
            srcSet={toImgProxyUrl(
              config.imgproxyUrl,
              props.source,
              R.assoc('resizingWidth', width, options)
            )}
          />
        ))}
      {/* Normal image source with the normal options */}
      <ThemeUI.Image
        {...props}
        alt={props.alt}
        src={toImgProxyUrl(config.imgproxyUrl, props.source, options)}
        sx={props.sx}
      />
    </picture>
  )
}

DynamicImage.propTypes = {
  alt: PropTypes.string,
  cropWidth: PropTypes.number,
  cropHeight: PropTypes.number,
  enlarge: PropTypes.bool,
  extension: PropTypes.oneOf(['jpg', 'png', 'webp']),
  gravity: PropTypes.oneOf([
    'no',
    'so',
    'ea',
    'we',
    'noea',
    'nowe',
    'soea',
    'sowe',
    'ce',
    'sm',
    'fp',
  ]),
  imgproxyUrl: PropTypes.string,
  resizingHeight: PropTypes.number,
  resizingType: PropTypes.oneOf(['fill', 'fit', 'auto']),
  resizingWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  source: PropTypes.string.isRequired,
  focusPointX: PropTypes.number,
  focusPointY: PropTypes.number,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,

  sx: PropTypes.object,
}
