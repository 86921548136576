// TODO: Implement namespaced module exports for GameTile here, instead of
//  having separate files in BEM-like notation

import * as React from 'react'
import * as PropTypes from 'prop-types'
import * as FramerMotion from 'framer-motion'

export function Animate(props) {
  return (
    <FramerMotion.motion.div
      initial={{
        scale: 0,
      }}
      animate={{
        scale: 1,
      }}
      exit={{
        scale: 0,
      }}
      transition={{
        delay: 0.03 * props.tileIndex,
      }}
    >
      {props.children}
    </FramerMotion.motion.div>
  )
}

Animate.propTypes = {
  children: PropTypes.node,
  tileIndex: PropTypes.number.isRequired,
}
