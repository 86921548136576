import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import * as PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'

import * as BaseGameTile from '../base-game-tile'
import * as Constants from '../constants'
import { Amount } from '../amount'

export function GameTileCasino(props) {
  const translate = Herz.I18n.useTranslate()

  const session = Herz.Auth.useSession()

  const playPath = `/play${props.game.liveCasinoAttributes ? '-live' : ''}/${
    props.game.id
  }`

  return (
    <BaseGameTile.GameLink
      key={props.game.title}
      to={
        props.game.loginRequired && !session.authenticated
          ? `?login=me&referrer=${playPath}`
          : playPath
      }
    >
      <BaseGameTile.Background
        backgroundUrl={props.game.backgroundUrl}
        backgroundOverlayUrl={props.game.backgroundOverlayUrl}
        backgroundOverlayImageAlignment={
          props.game.backgroundOverlayImageAlignment
        }
        size={props.size}
      >
        <ThemeUI.Flex
          sx={{
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
            position: 'relative',
          }}
        >
          <BaseGameTile.GameProvider size={props.size}>
            {props.game?.displayProvider || props.game?.provider}
          </BaseGameTile.GameProvider>
          {props.game.titleUrl ? (
            <BaseGameTile.TitleImage
              size={props.size}
              titleUrl={props.game.titleUrl}
              alt={props.game.title}
            />
          ) : (
            <React.Fragment>
              <ThemeUI.Box
                sx={{
                  height: '50%',
                  position: 'absolute',
                  left: '0px',
                  bottom: '0px',
                  right: '0px',
                  background:
                    'linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000)',
                  borderBottomLeftRadius: 1,
                  borderBottomRightRadius: 1,
                }}
              />
              <BaseGameTile.Title size={props.size}>
                {props.game.title}
              </BaseGameTile.Title>
            </React.Fragment>
          )}

          {props.game.jackpotValue != null && props.game.jackpotValue !== 0 && (
            <ThemeUI.Text
              sx={{
                color: 'faded-white',
              }}
            >
              {translate('game.jackpot')}{' '}
              <Amount currency={props.game.jackpotCurrency}>
                {props.game.jackpotValue}
              </Amount>
            </ThemeUI.Text>
          )}
        </ThemeUI.Flex>
      </BaseGameTile.Background>
    </BaseGameTile.GameLink>
  )
}

Herz.I18n.Loader.preload(['game.jackpot'], GameTileCasino)

GameTileCasino.propTypes = {
  alignBottom: PropTypes.bool,
  size: PropTypes.oneOf(Constants.GameTileSizes),
  game: PropTypes.object,
}
