import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import * as R from 'ramda'
import * as PropTypes from 'prop-types'
import Lazyload from 'react-lazyload'

import * as Utils from './utils'
import * as Constants from './constants'
import { DynamicImage } from './dynamic-image'
import { Link } from './link'
import { imageWidth } from './game-tile/utils'
import { useImgproxyUrl } from './use-imgproxy-url'

export function GameLink(props) {
  return (
    <Link
      {...props}
      sx={{
        'textDecoration': 'none',
        '&:hover': {
          color: 'white',
        },
      }}
    >
      {props.children}
    </Link>
  )
}

GameLink.propTypes = {
  children: PropTypes.node,
}

export function Background(props) {
  const backgroundUrl = useImgproxyUrl(props.backgroundUrl, {
    extension: 'jpg',
    resizingWidth: imageWidth(props.size, 1.7),
  })

  const overlayUrl = useImgproxyUrl(props.backgroundOverlayUrl, {
    resizingType: 'fit',
    resizingWidth: imageWidth(props.size),
    dpr: 1,
  })

  const overlayBackgroundPosition = R.equals(
    props.backgroundOverlayImageAlignment,
    'bottom'
  )

  function backgroundStyles() {
    if (props.backgroundUrl == null || !backgroundUrl) {
      return {
        backgroundImage: Utils.Css.url('/images/default-tile-background.svg'),
        backgroundSize: '40px',
        backgroundColor: 'hsl(284, 70%, 5%)',
      }
    }
    if (overlayUrl) {
      return {
        backgroundImage: Utils.Css.list([
          Utils.Css.url(overlayUrl),
          Utils.Css.url(backgroundUrl),
        ]),
        backgroundOrigin: Utils.Css.list(['content-box', 'border-box']),
        backgroundPosition: Utils.Css.list(
          overlayBackgroundPosition
            ? ['bottom center', 'center']
            : ['center', 'center']
        ),
        backgroundRepeat: Utils.Css.list(['no-repeat', 'no-repeat']),
        backgroundSize: Utils.Css.list(['contain', 'cover']),
      }
    }

    return {
      backgroundImage: Utils.Css.url(backgroundUrl),
      backgroundSize: 'cover',
    }
  }

  return (
    <ThemeUI.Card
      variant={`game.${props.size}`}
      sx={{
        'position': 'relative',
        'opacity': props.disabled ? 0.5 : 1,
        'pt': [1, 2],
        ...backgroundStyles(),
        'transition': 'transform 150ms ease-out',
        '&:hover, &:active': {
          transform: 'scale(0.95)',
        },
      }}
    >
      {props.children}
    </ThemeUI.Card>
  )
}

Background.propTypes = {
  backgroundUrl: PropTypes.string,
  backgroundOverlayUrl: PropTypes.string,
  backgroundOverlayImageAlignment: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(Constants.GameTileSizes),
  disabled: PropTypes.bool,
}

export function Title(props) {
  return (
    <ThemeUI.Text
      variant={`game.${props.size}`}
      sx={{
        mt: 'auto',
        mb: '10px',
        mx: 1,
        zIndex: 1,
        textAlign: 'center',
        lineHeight: '1.25',
      }}
    >
      {props.children}
    </ThemeUI.Text>
  )
}

Title.propTypes = {
  size: PropTypes.oneOf(Constants.GameTileSizes),
  children: PropTypes.string,
}

export function TitleImage(props) {
  return (
    <DynamicImage
      alt={props.alt}
      resizingWidth={imageWidth(props.size)}
      resizingType="fit"
      source={props.titleUrl}
      sx={{
        maxHeight: '50%',
        maxWidth: '95%',
        pb: [0, 1],
        mt: 'auto',
      }}
    />
  )
}

TitleImage.propTypes = {
  alt: PropTypes.string,
  titleUrl: PropTypes.string,
  size: PropTypes.oneOf(Constants.GameTileSizes),
}

export function Loader(props) {
  return (
    <Lazyload once height={200} offset={100}>
      {props.children}
    </Lazyload>
  )
}

Loader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export function GameProvider(props) {
  return (
    <ThemeUI.Text
      variant="game-tile"
      sx={{
        fontSize: props.size === 'small' ? 'xs' : 'sm',
        mx: 0,
      }}
    >
      {props.children}
    </ThemeUI.Text>
  )
}

GameProvider.propTypes = {
  children: PropTypes.string,
  size: PropTypes.oneOf(Constants.GameTileSizes),
}
