import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import * as PropTypes from 'prop-types'

import * as Constants from '../constants'
import { NavLink } from '../nav-link'

export function SubMenu(props) {
  return (
    <ThemeUI.Flex
      sx={{
        position: 'sticky',
        top: `var(${Constants.CssVariables.HEADER_WRAPPER_HEIGHT})`,
        backgroundColor: 'secondary-bg',
        color: 'white',
        px: 1,
        zIndex: 'high',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {props.searchbar}
      <ThemeUI.Flex
        sx={{
          'width': 'auto',
          'overflow': props.disableOverflow ? 'hidden' : 'auto',
          'whiteSpace': 'nowrap',
          'alignItems': 'center',

          '& > *': {
            flexShrink: 0,
          },
        }}
      >
        {props.children}
      </ThemeUI.Flex>
    </ThemeUI.Flex>
  )
}

SubMenu.Link = SubMenuLink

SubMenu.propTypes = {
  children: PropTypes.node,
  disableOverflow: PropTypes.bool,
  searchbar: PropTypes.node,
}

function SubMenuLink(props) {
  return (
    <NavLink
      to={props.to}
      sx={{
        'display': 'inline-flex',
        'fontSize': 'sm',
        'lineHeight': '16px',
        'textTransform': 'capitalize',
        'py': ['14px', null, '18px'],
        'px': '14px',
        'fontWeight': '600',

        '&.active': {
          color: 'primary-hover',
        },
        ...props.sx,
      }}
      active={props.active}
    >
      {props.children}
    </NavLink>
  )
}

SubMenuLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  disableOverflow: PropTypes.bool,
  sx: PropTypes.object,
  active: PropTypes.bool,
}
