import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'

import { HtmlContent } from './html-content'

export function SeoTextSection() {
  const route = ReactRouter.useRouteMatch()

  const translationKey = `${
    route.path === '/' ? 'landing' : route.path.split('/', 2)[1]
  }.seo-text`

  const translate = Herz.I18n.useTranslate(() => [translationKey])

  return (
    <HtmlContent
      sx={{
        fontSize: 'xs',
        py: 2,
        lineHeight: '1.5em',
        p: {
          color: 'white-alpha.600',
        },
      }}
    >
      {translate(translationKey) || ''}
    </HtmlContent>
  )
}
