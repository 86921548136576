import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ThemeUI from 'theme-ui'
import * as PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'

import * as Promotions from './redux-promotions'
import { Button } from './buttons/button'
import { useActivePromotionsHashmap } from './use-active-promotions'
import { useTranslationImgproxyUrl } from './use-imgproxy-url'

export function VerticalBanner() {
  const session = Herz.Auth.useSession()
  const location = ReactRouter.useLocation()
  const vertical = location.pathname.split('/')
  vertical.shift()

  const promotions = useActivePromotionsHashmap()

  // Filter only items for banner.
  const verticalPromotions = Promotions.getLobbyBannerPromotions(
    Promotions.getPromotionsFilteredByTag(promotions, {
      tag: vertical[1] || vertical[0],
    })
  )

  const promotion = verticalPromotions[0]

  if (promotion?.key) {
    return (
      <Banner
        contentKey={promotion.key}
        ctaLink={
          session.authenticated ? promotion.loggedInButtonLink : `?register=me`
        }
      />
    )
  }

  return null
}

function Banner(props) {
  const translate = Herz.I18n.useTranslate(
    () => [
      `promotions.${props.contentKey}.img-src`,
      `promotions.${props.contentKey}.img-src.overlay`,
      `promotions.${props.contentKey}.title`,
      `promotions.${props.contentKey}.description`,
      `promotions.${props.contentKey}.cta`,
    ],
    [props.contentKey]
  )

  const backgroundImageUrl = useTranslationImgproxyUrl(
    translate(`promotions.${props.contentKey}.img-src`)
  )

  const foregroundImageUrl = useTranslationImgproxyUrl(
    translate(`promotions.${props.contentKey}.img-src.overlay`),
    [{ resizingWidth: 180 }, { resizingWidth: 400 }]
  )

  return (
    <ThemeUI.Box
      sx={{
        backgroundColor: 'promotion-default',
        backgroundImage: `url("${backgroundImageUrl}")`,
        backgroundPosition: ['center top', null, 'center'],
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
      }}
    >
      <ThemeUI.Container
        sx={{
          alignItems: 'flex-start',
          backgroundImage: [
            `url("${foregroundImageUrl[0]}")`,
            null,
            `url("${foregroundImageUrl[1]}")`,
          ],
          backgroundPositionY: 'center',
          backgroundPositionX: 'right',
          backgroundRepeat: 'no-repeat',
          backgroundSize: ['auto 50%', 'auto 70%'],
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          minHeight: ['200px', null, '300px'],
          paddingBottom: 20,
          paddingTop: 20,
          px: [2, null, 4],
        }}
      >
        <ThemeUI.Box
          sx={{
            fontSize: ['2xl', null, '4xl'],
            fontFamily: 'heading',
            fontWeight: '700',
          }}
        >
          {translate(`promotions.${props.contentKey}.title`)}
        </ThemeUI.Box>

        <ThemeUI.Text
          sx={{
            color: 'white',
            fontFamily: 'body',
            fontSize: ['sm', null, 'lg'],
            lineHeight: 2,
            maxWidth: '60%',
            my: [1, null, 3],
          }}
        >
          {translate(`promotions.${props.contentKey}.description`)}
        </ThemeUI.Text>

        {props.ctaLink && (
          <Button as={ReactRouter.Link} to={props.ctaLink} variant={'primary'}>
            {translate(`promotions.${props.contentKey}.cta`)}
          </Button>
        )}
      </ThemeUI.Container>
    </ThemeUI.Box>
  )
}

Banner.propTypes = {
  contentKey: PropTypes.string,
  ctaLink: PropTypes.string,
}

// for @loadable/components
export default VerticalBanner
