import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'

import * as Utils from './utils'
import { Button } from './buttons/button'
import { HttpStatus } from './http-status'

export function NotFound() {
  const translate = Herz.I18n.useTranslate()

  return (
    <HttpStatus code={404}>
      <ThemeUI.Box
        sx={{
          p: 6,
          height: '100%',
          backgroundColor: 'page-bg',
        }}
      >
        <ThemeUI.Flex
          sx={{
            width: '90%',
            backgroundColor: 'white',
            mx: 'auto',
            p: 3,
            alignItems: 'center',
            flexDirection: 'column',
            ...Utils.Css.mixins.contentHeight(true),
          }}
        >
          <ThemeUI.Heading
            as="h1"
            sx={{
              fontFamily: 'heading',
            }}
          >
            404
          </ThemeUI.Heading>

          <ThemeUI.Text sx={{ mt: 2, mb: 4 }} variant="default">
            {translate('page-not-found.title')}
          </ThemeUI.Text>

          <ReactRouter.Link
            to="/"
            component={Utils.Components.RootLink}
            style={{ textDecoration: 'none' }}
          >
            <Button>{translate('page-not-found.action')}</Button>
          </ReactRouter.Link>
        </ThemeUI.Flex>
      </ThemeUI.Box>
    </HttpStatus>
  )
}

Herz.I18n.Loader.preload(
  ['page-not-found.title', 'page-not-found.action'],
  NotFound
)

// for @loadable/components
export default NotFound
