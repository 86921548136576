import gql from 'graphql-tag'

import * as Constants from '../constants'

// Needs to be in separate file to avoid circular imports
export function imageWidth(size, multiplier = 1) {
  switch (size) {
    case 'large':
      return Math.floor(Constants.GameTileWidth.LARGE * multiplier)
    case 'medium':
      return Math.floor(Constants.GameTileWidth.MEDIUM * multiplier)
    case 'midi':
      return Math.floor(Constants.GameTileWidth.MIDI * multiplier)
    default:
      return Math.floor(Constants.GameTileWidth.SMALL * multiplier)
  }
}

export const gameTileFragment = gql`
  fragment GameTileFragment on Game {
    title
    id
    backgroundUrl
    backgroundOverlayImageAlignment
    backgroundOverlayUrl
    titleUrl
    provider
    displayProvider
    blitzEnabled
    loginRequired
    liveCasinoAttributes {
      seatsTotal
      seatsAvailable
      tableOpen
    }
  }
`
