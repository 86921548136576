import * as React from 'react'
import * as R from 'ramda'
import * as ThemeUI from 'theme-ui'
import * as PropTypes from 'prop-types'
import * as FramerMotion from 'framer-motion'

import { useResponsiveValue } from '@theme-ui/match-media'

import * as Constants from './constants'
import { useGetLatest } from './use-get-latest'

const GRID_EXPANDED_VARIANT = 'grid-expanded'
const GRID_CONTRACTED_VARIANT = 'grid-contracted'
const INNER_PADDING = 12
const AnimatedGrid = FramerMotion.motion(ThemeUI.Grid)
const gridHeightVariants = {
  [GRID_CONTRACTED_VARIANT]: (height) => ({
    height,
  }),
  [GRID_EXPANDED_VARIANT]: {
    height: 'auto',
  },
}

export function GameRow(props) {
  const gridRowRef = React.useRef()
  const [visibleElements, setVisibleElements] = React.useState(0)
  const getOnVisibleElementsChange = useGetLatest(props.onVisibleElementsChange)

  const GAME_TILE_WIDTH = Constants.GameTileWidth[R.toUpper(props.size)]
  const GAME_TILE_HEIGHT =
    Constants.GameTileHeight[
      R.toUpper(props.size === 'small' ? 'medium' : props.size)
    ]

  React.useEffect(() => {
    if (getOnVisibleElementsChange()) {
      getOnVisibleElementsChange()(visibleElements)
    }
  }, [getOnVisibleElementsChange, visibleElements])

  React.useEffect(() => {
    /*
     * We use ResizeObserver instead of window#resize event because
     * window#resize does not run when we render the component initially,
     * so we need to run a 'useEffect' right after render when 'gridRowRef' is
     * ready.
     *
     * The problem with this is that React will run effects _after_ suspending,
     * and so our 'useEffect' will "see" the Suspense fallback element and measure the
     * 'gridRowRef' DOM node values as if it didn't exist (all zeroes).
     *
     * Bug report (won't be solved until concurrent mode):
     *   https://github.com/facebook/react/issues/18706
     *
     * ResizeObserver browser support:
     *   https://caniuse.com/?search=resizeobserver
     * */
    if (gridRowRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        const children = Array.from(gridRowRef.current.children)
        const baseOffset = children[0]?.offsetTop
        const breakIndex = children.findIndex(
          (item) => item.offsetTop > baseOffset
        )

        const maxVisibleItems = breakIndex === -1 ? children.length : breakIndex
        setVisibleElements(maxVisibleItems)
      })

      const gridRow = gridRowRef.current
      resizeObserver.observe(gridRow)
      return () => {
        resizeObserver.unobserve(gridRow)
      }
    }
    // We only need to register the observer, so no deps ever needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRowRef.current])

  const height = useResponsiveValue([
    `${GAME_TILE_HEIGHT * Constants.GAME_TILE_MULTIPLIER}px`,
    // We need to do this check to force the "small" tile to be
    // a certain height on > mobile screens.
    props.size === 'small'
      ? `${Constants.GameTileHeight.SMALL}px`
      : `${GAME_TILE_HEIGHT}px`,
  ])

  return (
    <AnimatedGrid
      variants={gridHeightVariants}
      animate={
        props.isUnlimited ? GRID_EXPANDED_VARIANT : GRID_CONTRACTED_VARIANT
      }
      custom={height}
      ref={gridRowRef}
      sx={{
        mt: 2,

        ...props.sx,

        gridTemplateColumns: [
          props.size === 'small'
            ? `repeat(3, 1fr)`
            : `repeat(auto-fit, minmax(140px, 1fr))`,
          `repeat(auto-fit, minmax(${GAME_TILE_WIDTH}px, 1fr))`,
        ],
        overflow: 'hidden',
      }}
    >
      <React.Fragment>
        {typeof props.children === 'function'
          ? props.children(props.games)
          : props.children}
      </React.Fragment>
    </AnimatedGrid>
  )
}

GameRow.propTypes = {
  games: PropTypes.array,
  size: PropTypes.oneOf(Constants.GameTileSizes),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  sx: PropTypes.object,
  /**
   * `true` - if you want all games you pass to be rendered, regardless of how
   * many rows they will take up.
   * You must provide `true` if you want to use the `maxElements` and `maxRows`
   * props.
   *
   * `false` - allow the component to fit one row of elements on the screen,
   * and hide the rest.
   */
  isUnlimited: PropTypes.bool,
  onVisibleElementsChange: PropTypes.func,
}

GameRow.defaultProps = {
  games: [],
  sx: { gridGap: `${INNER_PADDING}px` },
  size: 'medium',
}
