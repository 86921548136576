import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import * as ThemeUI from 'theme-ui'
import * as Urql from 'urql'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

import * as Herz from '@rushplay/herz'
import * as Offers from '@rushplay/offers'
import { lazy as loadable } from '@loadable/component'

import * as BaseGameTile from './base-game-tile'
import * as CombinedSelectors from './combined-selectors'
import * as Constants from './constants'
import * as Cookies from './cookies'
import * as Icons from './icons'
import * as Suspense from './suspense'
import * as Utils from './utils'
import { Amount } from './amount'
import { Button } from './buttons/button'
import { GameRow } from './game-row'
import { GameSectionMenu } from './game-section/game-section-menu'
import { GameTileCasino } from './game-tile/game-tile-casino'
import { GameTileLiveCasino } from './game-tile/live-casino'
import { Partners } from './partners'
import { PaymentMethods } from './payment-methods'
import { SeoTextSection } from './seo-text-section'
import { Spinner } from './spinner'
import { gameTileFragment } from './game-tile/utils'
import { useLiveCasinoTablesQuery } from './use-live-casino-query'
import { useTranslationImgproxyUrl } from './use-imgproxy-url'

// Hack to get around the app crashing because it cannot resolve the
// dynamic translations inside component on the server and crashing the app
// This trick forces it to happen on the client only where data is resolved.
const LoginDrawer = loadable(() => import('./drawers/login-drawer'), {
  ssr: false,
})

export function LandingPage() {
  const translate = Herz.I18n.useTranslate()
  const history = ReactRouter.useHistory()
  const session = Herz.Auth.useSession()
  const { country } = Utils.Configuration.useConfiguration()
  const dispatch = ReactRedux.useDispatch()
  const offers = ReactRedux.useSelector((state) =>
    CombinedSelectors.getCalculatedOffers(state)
  )
  const [returningPlayer] = Cookies.useCookie(
    Constants.Cookies.RETURNING_PLAYER
  )
  const [showMore, setShowMore] = React.useState(false)

  const bannerBackgroundSrc = useTranslationImgproxyUrl(
    translate('landing-page.banner.background-image'),
    {
      resizingHeight: 335,
    }
  )

  const flagImageSrc = useTranslationImgproxyUrl(
    translate('landing-page.banner.australian-flag'),
    {
      resizingHeight: 143,
    }
  )

  const flagKey = country?.alpha2 === 'AU' ? flagImageSrc : ''

  React.useEffect(() => {
    dispatch(Offers.fetchDepositOffers())
  }, [dispatch])

  // Redirect to casino if logged in
  if (session.authenticated) {
    history.replace('/casino')
  }

  return (
    <Suspense.Boundary>
      <ThemeUI.Box
        sx={{
          width: '100%',
          height: '335px',
          backgroundColor: 'hero-banner-bg',
          backgroundImage: returningPlayer
            ? `url(${bannerBackgroundSrc})`
            : `url(${flagKey}), url(${bannerBackgroundSrc})`,
          backgroundSize: returningPlayer ? 'cover' : '282px 143px, cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: returningPlayer ? 'right' : 'left top, right',
        }}
      >
        <ThemeUI.Box
          sx={{
            maxWidth: 'container.lg',
            margin: 'auto',
            height: '100%',
            position: 'relative',
          }}
        >
          {returningPlayer ? (
            <ReturningPlayer />
          ) : (
            offers?.length !== 0 && <NewVisitor offers={offers} />
          )}
        </ThemeUI.Box>
      </ThemeUI.Box>
      <ThemeUI.Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: returningPlayer ? '0px' : ['160px', null, '0px'],
          mb: 2,
          mx: 'auto',
          maxWidth: 'container.lg',
          px: [2, null, null, '0px'],
          py: [2, null, null, 3],
        }}
      >
        <ThemeUI.Box
          sx={{
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: 'divider',
            py: 2,
          }}
        >
          <PaymentMethods />
        </ThemeUI.Box>

        <Suspense.Boundary>
          <CasinoSection />
        </Suspense.Boundary>
        <Suspense.Boundary>
          <LiveCasinoSection />
        </Suspense.Boundary>
        <ThemeUI.Box
          sx={{
            py: [1, null, 4],
            px: 1,
            mt: [3, null, 4],
            mb: [3, null, 2],
            backgroundColor: 'dark-bg',
            borderRadius: 1,
          }}
        >
          <Partners />
        </ThemeUI.Box>
        <ThemeUI.Grid
          sx={{
            gridTemplateColumns: ['1fr', null, 'repeat(4, 1fr)'],
            gap: [1, null, 2],
            mb: 3,
          }}
        >
          {Array.from({ length: 4 }, (_, index) => (
            <ThemeUI.Flex
              key={index}
              sx={{
                flexDirection: 'column',
                backgroundColor: 'dark-bg',
                borderRadius: 2,
                textAlign: 'center',
                px: 2,
                py: '19px',
              }}
            >
              <ThemeUI.Image
                loading="lazy"
                sx={{ width: ['90px', null, '100px'], margin: '0 auto' }}
                src={translate(`landing-page.ups-${index + 1}.img`)}
              />
              <ThemeUI.Text
                as="h3"
                sx={{
                  fontWeight: 'bold',
                  fontSize: '15px',
                  lineHeight: '16px',
                  letterSpacing: ' 0.9px',
                  textTransform: 'uppercase',
                  mt: 2,
                  mb: 0,
                }}
              >
                {translate(`landing-page.ups-${index + 1}.title`)}
              </ThemeUI.Text>
              <ThemeUI.Text
                sx={{
                  fontSize: 'sm',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '0.28px',
                  opacity: '0.8',
                }}
              >
                {translate(`landing-page.ups-${index + 1}.description`)}
              </ThemeUI.Text>
            </ThemeUI.Flex>
          ))}
        </ThemeUI.Grid>
        <ThemeUI.Box
          sx={{
            position: 'relative',
            overflow: showMore ? null : 'hidden',
            maxHeight: showMore ? null : '350px',
          }}
        >
          <SeoTextSection />
          <ThemeUI.Box
            sx={{
              bottom: '0px',
              top: '30%',
              right: '0px',
              left: '0px',
              background: showMore
                ? null
                : 'linear-gradient(180deg, rgba(28, 65, 82, 0.40) 0%, #1C4152 91.25%)',
              position: 'absolute',
            }}
          />
        </ThemeUI.Box>
        <Button
          sx={{
            zIndex: '1',
            maxWidth: '220px',
            mx: 'auto',
            mb: 2,
          }}
          variant="primary.outlined"
          onClick={() => setShowMore((prev) => !prev)}
        >
          {showMore
            ? translate('landing-page.show-less')
            : translate('landing-page.show-more')}
        </Button>
      </ThemeUI.Box>
    </Suspense.Boundary>
  )
}

Herz.I18n.Loader.preload(
  [
    'landing-page.ups-1.title',
    'landing-page.ups-1.description',
    'landing-page.ups-2.title',
    'landing-page.ups-2.description',
    'landing-page.ups-3.title',
    'landing-page.ups-3.description',
    'landing-page.ups-4.title',
    'landing-page.ups-4.description',
    'landing-page.ups-1.img',
    'landing-page.ups-2.img',
    'landing-page.ups-3.img',
    'landing-page.ups-4.img',
    'landing-page.banner.australian-flag',
    'landing-page.banner.background-image',
    'landing-page.welcome-offer.part-1',
    'landing-page.welcome-offer.part-2',
    'landing-page.banner.cta',
    'landing-page.show-less',
    'landing-page.show-more',
  ],
  LandingPage
)

const CatalogQuery = gql`
  query CatalogQuery {
    catalog(key: "landing-page") {
      ... on GetCatalogSuccess {
        categories {
          id
          sections {
            id
            key
            tileSize
            games {
              ...GameTileFragment
            }
          }
        }
      }
      ... on GetCatalogFailure {
        error
        errorCode
      }
    }
  }
  ${gameTileFragment}
`

function getCasinoTranslationKey(key) {
  return ['casino', key].join('.')
}

function ReturningPlayer() {
  return (
    <ThemeUI.Box
      sx={{
        width: '318px',
        position: 'absolute',
        left: ['50%', 2],
        top: '0px',
        transform: ['translate(-50%, 0)', 'none'],
      }}
    >
      <LoginDrawer isLandingPage />
    </ThemeUI.Box>
  )
}

Herz.I18n.Loader.preload(['landing-page.welcome-back'], ReturningPlayer)

function NewVisitor(props) {
  const translate = Herz.I18n.useTranslate()

  return (
    <ThemeUI.Box
      sx={{
        position: 'absolute',
        background: [
          'linear-gradient(180deg, #122A36 0%, #194053 53.65%, #1C4152 100%)',
          null,
          'transparent',
        ],
        borderRadius: 2,
        border: ['3px solid #1C4152', null, 'none'],
        borderBottom: 'none',
        px: 2,
        left: ['50%', null, 2],
        transform: ['translate(-50%, 0)', null, 'none'],
        bottom: ['-170px', null, '55px'],
        fontWeight: 600,
        lineHeight: '54px',
        zIndex: '0',
      }}
    >
      <ThemeUI.Box
        sx={{
          margin: 'auto',
          textAlign: 'center',
          color: 'primary',
        }}
      >
        <ThemeUI.Box
          sx={{
            fontSize: ['24px', null, '35px'],
            textAlign: 'center',
          }}
        >
          {translate('landing-page.welcome-offer.part-1', {
            bonusMoneyPercent: props.offers[0]?.bonusMoneyPercent,
          })}
        </ThemeUI.Box>
        <ThemeUI.Flex>
          <ThemeUI.Box
            sx={{
              fontSize: ['79px', null, '85px'],
              lineHeight: '0.75em',
              fontWeight: 'bold',
              color: 'white',
              margin: 'auto',
            }}
          >
            <Amount maximumFractionDigits="0">
              {props.offers[0]?.bonusMoneyPercentMaxCents}
            </Amount>
          </ThemeUI.Box>
        </ThemeUI.Flex>

        <ThemeUI.Box
          sx={{
            fontSize: ['24px', null, '40px'],
            pt: 1,
          }}
        >
          {translate('landing-page.welcome-offer.part-2', {
            maxFixedFreespins: props.offers[0]?.maxFixedFreespins,
          })}
        </ThemeUI.Box>
      </ThemeUI.Box>
      <Button
        as={ReactRouter.Link}
        variant="primary"
        to="?register=me"
        sx={{
          width: ['290px', null, '100%'],
        }}
      >
        {translate('landing-page.banner.cta')}
      </Button>
    </ThemeUI.Box>
  )
}

NewVisitor.propTypes = {
  offers: PropTypes.array,
}

Herz.I18n.Loader.preload(
  [
    'landing-page.welcome-offer.part-1',
    'landing-page.welcome-offer.part-2',
    'landing-page.banner.cta',
  ],
  NewVisitor
)

function CasinoSection() {
  const [casinoResponse] = Urql.useQuery({
    query: CatalogQuery,
  })

  const error = casinoResponse?.data?.catalog?.error

  const session = Herz.Auth.useSession()
  const sections = error
    ? []
    : casinoResponse.data?.catalog?.categories[0]?.sections || []

  const translate = Herz.I18n.useTranslate(
    () => [
      ...sections.flatMap((section) => [
        getCasinoTranslationKey(section.key),
        `landing-page.${section.key}.seo`,
      ]),
      getCasinoTranslationKey('main'),
      'landing-page.casino.view-all',
    ],
    [casinoResponse.data]
  )

  if (casinoResponse.fetching) {
    return (
      <ThemeUI.Flex
        sx={{
          height: '800px',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </ThemeUI.Flex>
    )
  }

  if (error) {
    return (
      <ThemeUI.Flex sx={{ justifyContent: 'center', pt: '1' }}>
        {translate('no-game-catalog-for-brand')}
      </ThemeUI.Flex>
    )
  }

  return (
    <>
      {sections.map((section) => (
        <ThemeUI.Box sx={{ mt: 4 }} key={section.key}>
          <GameSectionMenu
            title={translate(getCasinoTranslationKey(section.key))}
            seo={
              !session.authenticated &&
              translate(`landing-page.${section.key}.seo`)
            }
            linkText={translate(`landing-page.casino.view-all`)}
            icon={Icons.Camera}
          />
          <GameRow
            isUnlimited
            size={section.tileSize}
            sx={{
              gridRowGap: [2, null, '26px'],
              gridColumnGap: [1, null, '26px'],
            }}
            games={section.games}
          >
            {(games) =>
              games.map((game) => (
                <BaseGameTile.Loader key={game.id}>
                  <GameTileCasino
                    key={game.id}
                    size={section.tileSize}
                    game={game}
                  />
                </BaseGameTile.Loader>
              ))
            }
          </GameRow>
        </ThemeUI.Box>
      ))}
    </>
  )
}

function LiveCasinoSection() {
  const translate = Herz.I18n.useTranslate()
  const session = Herz.Auth.useSession()

  const [liveCasinoResponse, filteredGames] =
    useLiveCasinoTablesQuery('lp_live_casino')

  if (liveCasinoResponse.fetching) {
    return (
      <ThemeUI.Flex
        sx={{
          height: '500px',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </ThemeUI.Flex>
    )
  }

  return filteredGames.length ? (
    <ThemeUI.Box sx={{ mt: 3 }}>
      <GameSectionMenu
        title={translate('landing-page.live-casino.title')}
        seo={
          !session.authenticated && translate('landing-page.live-casino.seo')
        }
        to="/live-casino"
        linkText={translate('landing-page.live-casino.view-all')}
        icon={Icons.Camera}
      />

      <GameRow isUnlimited sx={{ gridGap: 1 }} games={filteredGames}>
        {(games) =>
          games.map((game) => (
            <BaseGameTile.Loader key={game.id}>
              <GameTileLiveCasino key={game.id} size="large" game={game} />
            </BaseGameTile.Loader>
          ))
        }
      </GameRow>
    </ThemeUI.Box>
  ) : null
}

Herz.I18n.Loader.preload(
  [
    'landing-page.live-casino.seo',
    'landing-page.live-casino.title',
    'landing-page.live-casino.view-all',
    'no-game-catalog-for-brand',
  ],
  LiveCasinoSection
)

// for @loadable/components
export default LandingPage
